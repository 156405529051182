import React from 'react';
import Banner from '../components/Banner';
import Navigation from '../components/navigation2';
import '../styles/pages/realisation.css';

const Realisation = () => {
    return (
        <div>
            <Banner />
            <Navigation />
            <h1>Mes réalisations</h1>
            <div className='realisation'>
                <h2>La spirulinère, les bassins de Nicolas </h2>
                <p>Un jeune producteur de spiruline souhaitait plus de visibilité pour se faire connaitre.</p>
                <p>La mise en place d'une boutique de ecommerce, lui permet maintenant de vendre ces produits depuis son site web</p>
                <p>Pour découvrir ce producteur de spiruline 100% paysanne</p>
                <p>cliquez-ici !</p>
            </div>
            <div className='realisation'>
                <h2>Ohmyfood</h2>
                <p>Projet de site de réservation d'un restaurant avec le menu</p>
                <p>Pour visualiser, la partie du site web réalisée,</p>
                <p>cliquez-ici</p>
            </div>
        </div>

    );
};

export default Realisation;
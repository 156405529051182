import React from 'react';
// import Banner from '../components/Banner';
// import Navigation from '../components/navigation';
import Navigation from '../components/navigation2';
import Footer from '../components/Footer';
import flechedroiteverte from '../assets/flechedroiteverte.jpg';
import ciblevert from '../assets/ciblevert.jpg';
import '../styles/pages/arroweb.css';


const Arroweb = () => {
    return (
        <div>
            {/* <Banner /> */}
            <div className='blocPage'>
                <div className='blocArrow1'>
                    <Navigation />
                    <h1 className='arroweb_titre'>Pour un site fiable et durable</h1>
                    <h2 className='arroweb_titre'>Choissisez ArroWeb Développement</h2>
                </div>
                <div className='animationfleche'>
                    <div>
                        <img src={ciblevert} alt="cible" className='cibleflechedroite' />
                    </div>
                    <div className='loader'>
                        <img src={flechedroiteverte} alt="fleche" className='flechedroite' />
                    </div>
                    <p className='assurance'>ArroWeb Dévéloppement, c'est l'assurance d atteintre votre objectif</p>
                </div>
                <div className='blocArrow2'>
                    <p className='arroweb_texte'>ArroWeb Développement est né de la passion de l'informatique et du développement de son créateur Fabrice FLECHE</p>
                    <p className='arroweb_texte'>Titulaire d'une formation de niveau 5 en développement web</p>
                    <p className='arroweb_texte'>Avec la création de plusieurs sites internet</p>
                </div>
                <div className='blocArrow3'>
                    <p className='arroweb_texte'>Que vous cherchez à avoir de la visibilité sur internet ou que vous avez besoin d'un site de e-commerce pour vendre vos produits</p>
                    <p className='arroweb_texte'>ArroWeb Developpement est à votre écoute de vos besoins pour la réalisation de votre site web</p>
                    <p className='arroweb_texte'>Votre site web sera une solution sur-mesure</p>
                    <p className='arroweb_texte'>Nous établirons un cahier des charges, pour définir le design et les fonctions souhaitées</p>

                </div>
            </div>
            <Footer />
        </div>

    );
};

export default Arroweb;
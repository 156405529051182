import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import photo from '../assets/ciblevert.jpg';
import '../styles/components/navigation.css';

function Navigation() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            <button onClick={toggleMenu}>
                <div className='blocMenu'>
                    <img src={photo} alt="Cible" className='cible' /> Menu
                </div>
            </button>
            {isMenuOpen && (
                <ul ref={menuRef} style={{ border: '1px solid #ccc', padding: '10px', position: 'absolute', backgroundColor: '#fff' }}>
                    <li><NavLink to="/">
                        <p className='navigation_ul_li'>Accueil</p>
                    </NavLink></li>
                    <li><NavLink to="/arroweb">
                        <p className="nav_link">ArroWeb Développement</p>
                    </NavLink></li>
                    <li><NavLink to="/realisations">
                        <p className="nav_link">Mes réalisations</p>
                    </NavLink></li>
                    <li><NavLink to="/tarifs">
                        <p className="nav_link">Tarifs</p>
                    </NavLink></li>
                    <li>
                        <NavLink to="/contact">
                            <p className="nav_link">Contact</p>
                        </NavLink>
                    </li>
                </ul>
            )}
        </div>
    );
}

export default Navigation;
import React from 'react';
import { NavLink } from 'react-router-dom';
import Footer from '../components/Footer';
import video from '../assets/forest.mp4'
import '../styles/pages/home.css';

const Home = () => {
    return (
        <div>
            <header>
                <div className="overlay"></div>
                <video className='video2' autoPlay muted loop src={video} type="video/mp4"></video>
                <h1>ArroWeb Développement</h1>
                <NavLink to="/Arroweb">
                    <p className='services-link'>Créateur de site web</p>
                </NavLink>
            </header>
            <Footer />
        </div>
    );
};

export default Home;
import React from 'react';
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Banner from '../components/Banner';
import Navigation from '../components/navigation2';
import Footer from '../components/Footer';
import Formulaire from '../components/contact/Formulaire';
import '../styles/pages/contact.css';


const Contact = () => {
    // const { } = useLoadScript({ googleMapsApiKey: "" })
    return (
        <div>
            <Banner />
            <Navigation />
            <h2>Vous avez un projet ? </h2>
            <h2>Vous souhaitez avoir des informations ou obtenir un devis gratuit, n'hésitez pas à me contacter</h2>
            <div className='contact_mail'>
                <h2>Envoyer un mail à</h2>
                <a href="mailto:contact@arrowebdeveloppement.fr"><h2>contact@arrowebdeveloppement.fr</h2></a>
                <h2>ou compléter le formulaire ci-dessous</h2>
            </div>
            {/* <div>
                <iframe
                    title="googleMap_ferme"
                    width="600"
                    height="450"
                    loading="lazy"
                    // allowfullscreen
                    // referrerpolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/d/u/0/embed?mid=1w9lPJsk3aXjH7cN1ZvnDqjzaIFhPS48&ehbc=2E312F&q=Space+Needle,Seattle+WA">
                </iframe>
            </div> */}
            <Formulaire />
            <Footer />
        </div>

    );
};

export default Contact;
import React from 'react';
import Banner from '../components/Banner';
import Navigation from '../components/navigation2';
import Footer from '../components/Footer';
import '../styles/pages/mentions.css';

const Mentions = () => {
    return (
        <div>
            <Banner />
            <Navigation />
            <h1 className='mentions_titre'>Mentions Légales</h1>
            <p className='mentions_texte'>Le présent site est la propriété de ArroWeb Développement</p>
            <p className='mentions_texte'>Ayant son siège social :</p>
            <p className='mentions_texte2'>20 rue des chaillots</p>
            <p className='mentions_texte2'>71370 St Etienne en Bresse</p>
            <p className='mentions_texte2'>mail : contact@arrowebdeveloppement.fr</p>
            <p className='mentions_texte2'>SIRET : </p>
            <p className='mentions_texte'>Le site est hébergé par LWS – 2 rue jules ferry 88190 Golbey RCS Epinal 851 993 683 - APE 6311Z - SIRET 85199368300016</p>
            <p className='mentions_texte3'>Il a été conçu par ArroWeb Développement</p>
            <Footer />
        </div>
    );
};

export default Mentions;
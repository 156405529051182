import React from 'react';
import { NavLink } from 'react-router-dom';
import Banner from '../components/Banner';
import Navigation from '../components/navigation2';
import Footer from '../components/Footer';
import '../styles/pages/tarifs.css';

const Tarifs = () => {
    return (
        <div>
            <Banner />
            <div className='pagetarifs'>
                <Navigation />
                <h1 className='mentions_titre'>Vous avez un projet ? Vous souhaitez avoir des informations ou obtenir un devis gratuit, n'hésitez pas à me contacter</h1>
                <p className='blocformulaire'>
                    <NavLink to="/contact">
                        <p className='titre2'>Ouvrir le formulaire de contact</p>
                    </NavLink>
                </p>
                <div className='prestations'>
                    <div className='prestations_bloc bloc1'>
                        <h3 className='prestations_bloc_titre'>SITE VITRINE STANDARD</h3>
                        <p className='prestations_bloc_texte'>A partir de 899 €</p>
                    </div>
                    <div className='prestations_bloc bloc2'>
                        <h3 className='prestations_bloc_titre'>SITE VITRINE COMPLEXE</h3>
                        <p className='prestations_bloc_texte'>A partir de 1299 €</p>
                    </div>
                    <div className='prestations_bloc bloc2'>
                        <h3 className='prestations_bloc_titre'>SITE E-COMMERCE</h3>
                        <p className='prestations_bloc_texte'>A partir de 2299 €</p>
                    </div>
                    <div className='prestations_bloc bloc1'>
                        <h3 className='prestations_bloc_titre'>FORFAIT MAINTENANCE</h3>
                        <p className='prestations_bloc_texte'>200 € / AN</p>
                        <p className='prestations_bloc_texte'>Pour des petites modifications occasionelles, vous pouvez bénificier d'une tarification horaire à 40€ / HEURE </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Tarifs;